import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115')
];

export const server_loads = [0,6,2];

export const dictionary = {
		"/(orion)": [~39,[6]],
		"/404": [111],
		"/(auth)/admin": [19,[2]],
		"/(backoffice)/backoffice": [29,[3]],
		"/(backoffice)/backoffice/bandeiras": [30,[3]],
		"/(backoffice)/backoffice/bandeiras/[id]": [31,[3]],
		"/(backoffice)/backoffice/disparadores": [32,[3]],
		"/(backoffice)/backoffice/marretagens": [33,[3]],
		"/(backoffice)/backoffice/vendas": [34,[3]],
		"/(orion)/banners": [40,[6,7]],
		"/(orion)/banners/cadastrar": [42,[6,7]],
		"/(orion)/banners/[id]": [41,[6,7]],
		"/(bussola)/bussola": [35,[4]],
		"/(bussola)/bussola/(steps)/analise": [36,[4,5]],
		"/(bussola)/bussola/(steps)/analise/[lojaId]": [37,[4,5]],
		"/(bussola)/bussola/(steps)/analise/[lojaId]/[categoria]": [38,[4,5]],
		"/(auth)/cadastrar": [20,[2]],
		"/(orion)/cadastro-promocao": [~43,[6,8]],
		"/(orion)/categorias": [44,[6,9]],
		"/(orion)/categorias/cadastrar": [46,[6,9]],
		"/(orion)/categorias/[id]": [45,[6,9]],
		"/(orion)/cursos": [47,[6,10]],
		"/(orion)/cursos/ao-vivo": [50,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/(listar)": [53,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/cadastrar": [55,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/quizzes/(listar)": [56,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/quizzes/cadastrar/[tipo]": [~58,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/quizzes/[id]": [57,[6,10,11]],
		"/(orion)/cursos/ao-vivo/gerenciar/[id]": [54,[6,10,11]],
		"/(orion)/cursos/ao-vivo/[id]": [51,[6,10,11]],
		"/(orion)/cursos/ao-vivo/[id]/ingressar": [~52,[6,10,11]],
		"/(orion)/cursos/gerenciar/(listar)": [59,[6,10]],
		"/(orion)/cursos/gerenciar/cadastrar": [61,[6,10]],
		"/(orion)/cursos/gerenciar/[id]": [60,[6,10]],
		"/(orion)/cursos/pesquisar": [62,[6,10]],
		"/(orion)/cursos/solucoes": [~63,[6,10]],
		"/(orion)/cursos/solucoes/[id]": [~64,[6,10]],
		"/(orion)/cursos/solucoes/[id]/[treinamento]": [65,[6,10]],
		"/(orion)/cursos/[id]": [48,[6,10]],
		"/(orion)/cursos/[id]/[aula]": [49,[6,10]],
		"/erro": [~113],
		"/(orion)/fidelizamais": [66,[6,12]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]": [~67,[6,12,13]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/ativacao": [68,[6,12,13]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/configuracoes": [69,[6,12,13]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/revisao": [70,[6,12,13]],
		"/(orion)/fidelizamais/gerenciar": [71,[6,12]],
		"/(orion)/fidelizamais/gerenciar/[uuid]": [72,[6,12]],
		"/(orion)/fidelizamais/minhas-lojas": [73,[6,12]],
		"/icons": [114],
		"/(auth)/login": [21,[2]],
		"/(auth)/logout": [~22,[2]],
		"/(orion)/mais": [74,[6]],
		"/(orion)/mais/noticias": [75,[6]],
		"/(orion)/mais/noticias/lancamentos": [77,[6]],
		"/(orion)/mais/noticias/lancamentos/[id]": [78,[6]],
		"/(orion)/mais/noticias/[id]": [76,[6]],
		"/(orion)/mais/perfil": [79,[6]],
		"/(orion)/medicamentos": [80,[6,14]],
		"/(orion)/medicamentos/pbm": [81,[6,14]],
		"/(orion)/medicamentos/pbm/gerenciar": [82,[6,14]],
		"/(orion)/medicamentos/pbm/orientacoes": [83,[6,14,15]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar": [85,[6,14,15,16]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/cadastrar": [87,[6,14,15,16]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/[id]": [86,[6,14,15,16]],
		"/(orion)/medicamentos/pbm/orientacoes/[id]": [84,[6,14,15]],
		"/(orion)/noticias": [88,[6]],
		"/(orion)/noticias/gerenciar": [90,[6,17]],
		"/(orion)/noticias/gerenciar/cadastrar": [92,[6,17]],
		"/(orion)/noticias/gerenciar/[id]": [91,[6,17]],
		"/(orion)/noticias/lancamentos": [93,[6]],
		"/(orion)/noticias/lancamentos/[id]": [94,[6]],
		"/(orion)/noticias/[id]": [89,[6]],
		"/(orion)/ofertas": [95,[6]],
		"/(orion)/perfil": [96,[6]],
		"/privacidade": [115],
		"/(orion)/promocoes": [~97,[6]],
		"/(auth)/recuperar-senha": [23,[2]],
		"/(auth)/redefinir-senha": [24,[2]],
		"/(auth)/sso": [~25,[2]],
		"/(auth)/sso/[token]": [26,[2]],
		"/(trade-app)/trade-app": [100,[18]],
		"/(trade-app)/trade-app/contratos": [101,[18]],
		"/(trade-app)/trade-app/explorar": [102,[18]],
		"/(trade-app)/trade-app/explorar/[id]": [103,[18]],
		"/(trade-app)/trade-app/extrato": [104,[18]],
		"/(trade-app)/trade-app/extrato/display": [106,[18]],
		"/(trade-app)/trade-app/extrato/[idAcao]": [105,[18]],
		"/(trade-app)/trade-app/historico": [107,[18]],
		"/(trade-app)/trade-app/historico/[id]": [108,[18]],
		"/(trade-app)/trade-app/mais": [109,[18]],
		"/(trade-app)/trade-app/mais/perfil": [110,[18]],
		"/(orion)/trade": [98,[6]],
		"/(orion)/troca-email": [99,[6]],
		"/(auth)/v2sso": [~27,[2]],
		"/(auth)/v2sso/[token]": [~28,[2]],
		"/[...naoencontrada]": [~112]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';